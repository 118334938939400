import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import rehypeReact from 'rehype-react'

import Layout from '../components/layout'
import ZoomImg from '../components/zoomImg'
import Table from '../components/table'
import Link from '../components/link'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    img: ZoomImg,
    table: Table,
    a: Link,
  },
}).Compiler

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = page.excerpt
    const className = page.frontmatter.className || ''

    return (
      <Layout location={this.props.location} customClass={className}>
        <Helmet
          htmlAttributes={{ lang: 'cs' }}
          title={`${page.frontmatter.title} | ${siteTitle}`}
        >
          <meta name="description" content={siteDescription} />
        </Helmet>
        <article className="page">
          <header>
            <h1>{page.frontmatter.title}</h1>
          </header>
          <section className="page-content">{renderAst(page.htmlAst)}</section>
        </article>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        className
      }
    }
  }
`
